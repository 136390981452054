/* eslint-disable @typescript-eslint/no-explicit-any */
import { client } from '../models/client'
import { user } from '../models/user';
import { get } from '../api'
import { AxiosResponse } from 'axios';
import { Authentic } from '../models/authentification';
const controller = 'auth'

export async function LogInClient(dado: Authentic): Promise<any> {
    const http = await get();
    const { data } = await http.post<any, AxiosResponse>(`${controller}/signin/client`, dado);
    return data;
}

export async function LogInUser(dado: Authentic): Promise<any> {
    const http = await get();
    const { data } = await http.post<any, AxiosResponse>(`${controller}/signin/user`, dado);
    return data;
}



export async function refresh(client:boolean): Promise<any> {
    const http = await get();
    const { data } = await http.post<any, AxiosResponse>(`${controller}/refresh`, {client:client});
    return data;
}

//   export async function FindOne(id: number): Promise<client> {
//     const http = await get();
//     const { data } = await http.get<client[], AxiosResponse>(`${controller}/${id}`);
//     return data;
//   }
  
//   export async function Post(dado: client): Promise<client> {
//     const http = await get();
//     const { data } = await http.post<client, AxiosResponse>(`${controller}`, dado);
//      return data;
//   }
  
//    export async function Put(dado: client ,id: number): Promise<client> {
//     const http = await get();
//   const { data } = await http.patch<client, AxiosResponse>(`${controller}/${id}`, dado);
//      return data;
//   }
  
//   export async function Remove(id: number): Promise<client> {
//     const http = await get();
//     const { data } = await http.delete<client, AxiosResponse>(`${controller}/${id}`);
//      return data;
//   }
  