import { ref, computed } from "vue";
import { LogInClient, refresh } from "@/core/services/auth.service";
import * as storage from "./storage"
import { Credential } from "@/core/models/credential";
import swal from 'sweetalert2';
import { LoginResponse } from "@/core/models/loginResponse";
let token = localStorage.getItem("token");
if (token) token = JSON.parse(String(localStorage.getItem(token)));
const state = ref<LoginResponse>({});
const balance = ref<number>(0);
const eventBalance = ref<number>(0)
function ActionSetUser(payload: LoginResponse) {
    state.value = payload;
    storage.setLocalUsuario(payload)
    storage.setLocalToken(payload.access_token);
}
const actions = {
    
    login: async (credentials: Credential) => {
        try {
            const User = await LogInClient(credentials);
            ActionSetUser(User.body);
        } catch (error: any) {
            if (error.response.data.statusCode != 200 || error.response.data.statusCode != 204) 
                swal.fire({
                    icon: "warning",
                    text: `Usuário sem acesso ao sistema ou as credenciais estão erradas` ,
                    title: "Atenção"
                });
            throw new Error("Usuário sem acesso ao sistema")
        }
    },
    setUser: async (payload: any) => {
        ActionSetUser(payload);
    },
    logout: () => {
        localStorage.removeItem("token");
        localStorage.removeItem("usuario");
        state.value = {};
        storage.deleteLocalToken();
        storage.deleteLocalUsuario();
    },
    refreshToken: async () => {
        const dados = await refresh(true);
        ActionSetUser(dados);
    },
    ActionCheckToken() {
        try {
            const token = storage.getLocalToken();
            if (state.value.access_token) {
                return Promise.resolve(state.value.access_token);
            } else if (!token) {
                return this.logout()
            } else {
                ActionSetUser(storage.getLocalUsuario()); ''
                return Promise.resolve(token);
            }
        } catch (error) {
            actions.logout();
        }
    },
};
const getters = {
    getStatusLoggedIn: computed(() => {
        return !!(state.value && state.value.access_token)
    }),
    getToken: computed(() => {
        return state.value.access_token;
    }),
    getUser: computed(() => {
        return state.value;
    }),

};
export { getters, state, actions, balance, eventBalance };







